<template lang="pug">
.content
  section.container-fluid.m-t-20

    a-breadcrumb(separator=">")
      a-breadcrumb-item
        router-link(to="/") 控制台
      a-breadcrumb-item 产品管理
      
    .m-t-15.m-b-20
      h1.section-title.inline 产品管理
      a-button.m-l-15(size="small" @click="handleAdd") 新增产品
    
    vc-table.m-b-25(
      ref="table"
      :data="table"
      @change="handleTableChange")

</template>

<script>
//- import { mapState } from 'vuex'

import columns from '@/config/column.config'
import vcTable from '@/components/vcTable'

export default {
  name: 'ProductManagement',
  components: {
    'vc-table': vcTable
  },
  computed: {
    //- ...mapState({
    //-   ui: state => state.ui
    //- })
  },
  data() {
    return {
      // 查询条件参数
      queryParam: {},

      /*********************************************************
      * @description 表格对象
      *********************************************************/
      table: {
        loading: false,
        size: 'default',
        columns: columns.product(this),
        source: [],

        api: parameter => {
          return this.$http.get('product', {
            params: Object.assign(parameter, this.queryParam)
          })
        },
      
        // 批量操作
        actions: [
          { label: '删除', value: 'delete' },
        ],

        // 过滤
        filter: true,
        filters: [],
        
        // 额外
        extra: [],
        
        // 分页器
        pagination: {
          total: 0,
          current: parseInt(this.$route.query.paged) || 1,
          pageSize: 20,
          pageSizeOptions: ['20', '40', '60'],
        },
      }

    }
  },
  filters: {},
  methods: {
    /*********************************************************
     * @description vc-table 对象方法
     *********************************************************/
    async handleTableChange({ action, pagination, filters, sorter }) {
      if (action) {
        switch(action.do) {
          case 'delete':
            await this.$http.delete('product', { data: { ids: action.data } });
            this.$message.success('删除成功');
            this.$refs.table.refresh();
          break;
        }
        return;
      }

      // keyword: this.table.keyword
      console.log('handleTableChange', { action, pagination, filters, sorter })
      
      location.href = `/#/product?paged=${pagination.current}`
    },

    //- 新增/编辑操作方法
    handleAdd() {
      const route = this.$router.getRoutes().find(item => item.name === 'Product Add');
      if (route && this.$route.name !== 'Product Add') return this.$router.push({ name: 'Product Add' });
      console.log('handle add')
    },
    async handleEdit(id) {
      const route = this.$router.getRoutes().find(item => item.name === 'Product Edit');
      if (route && this.$route.name !== 'Product Edit') return this.$router.push({ name: 'Product Edit', params: { id } });
      console.log('handle edit')
    },

    /*********************************************************
     * @description 获取单个数据
     *********************************************************/
    async getDetail(id) {
      return await this.$http.get(`product/${id}`);
    },

    /*********************************************************
     * @description 删除单个数据
     *********************************************************/
    async deleteData(id) {
      try {
        await this.$http.delete(`product/${id}`);
        this.$message.success('删除成功');
        this.$refs.table.refresh();
      } catch(err) {
        this.$message.error(err.message)
      }
    },
  },
  created() {
    //- this.tableOption()
    //- getRoleList({ t: new Date() })
  },
}
</script>